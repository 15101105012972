import { ReactElement } from 'react';
import icons from 'shared-components/icons';
import { StandardItem } from 'shared-components/menuItems';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';
import { Environment, RouteItem } from 'dataTypes/common';

import { ExtractedAssetData } from 'TrackAndTrace/Assets/lib';
import { ASSET_TYPES } from 'SensorDataCommon/dataTypes';
import { documentLocationUrl, PICTURES } from './constants';
import { CheckboxOption, FacetDTO } from './dataTypes';

type ObjectWithTArray<T = object> = {
    [key:string]: T[]
}

export const joinLoggers: <T>(loggers: (ExtractedAssetData | ExtractedLoggerData)[])
    => ObjectWithTArray<T> = (loggers) => {
        return loggers.reduce((acc, marker) => {
            const key = `${marker.lastMeasuredLatitude}_${marker.lastMeasuredLongitude}`;

            acc[key] = [...(acc[key] || []), marker];
            return acc;
        }, {});
    };
type PathInfo = {
    param?: null,
    query?: {
        [key: string]: string
    },
}

export const parseParamAndQueries = (historyLocation) => {
    const pathInfo: PathInfo = {};

    const [param] = historyLocation.pathname.split('/').slice(-1);

    if (param !== ' ') {
        try {
            pathInfo.param = param;

            if (historyLocation.search) {
                pathInfo.query = historyLocation.search.replace('?', '').split('&').reduce((obj, query) => {
                    const [key, value] = query.split('=');
                    const queryObject = { ...obj };

                    queryObject[key] = value;

                    return queryObject;
                }, {});
            }
        } catch (error) {
            pathInfo.param = null;
            pathInfo.query = {};
            global.console.log(error);
        }
    }

    return pathInfo;
};
export const invertColor = (hex, theme) => {
    if (theme === 'default') return hex;

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16);
    const g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16);
    const b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);

    return `#${r.padStart(2, '0').slice(-2)}${g.padStart(2, '0').slice(-2)}${b.padStart(2, '0').slice(-2)}`;
};
export const nonNullKey = (object:Object) => {
    if (!object) return null;
    return Object.keys(object).find(key => object[key]);
};
export const nonNullObject = (object:Object) => {
    if (!object) return null;

    return Object.values(object).find(value => value);
};

export const downloadFileFromString = (string: string, filename: string, type = 'text/csv;charset=utf-8,%EF%BB%BF') => {
    const blob = new Blob([`\ufeff${ string}`], { type });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export const getOptionsFromFacets = (
    facets: FacetDTO[], field: string,
): string[] => {
    return facets.find(({ filterName }) => filterName === field)
        ?.counts
        ?.map(({ count, filterValue }) => (count > 0 ? filterValue : null))
        ?.filter(Boolean) || [];
};

export const getFieldCheckboxData = (
    {
        descriptionGroup,
        dictionary,
        facets = [],
        initialFacets = null,
        pluralName,
        renderTooltip = null,
        t,
        tgroup,
    }: {
        descriptionGroup?: string,
        dictionary?: {
            id: number,
            label: string,
        }[],
        facets: FacetDTO[],
        initialFacets?: FacetDTO[],
        pluralName: string,
        renderTooltip?: (value: string) => ReactElement,
        t?: Function,
        tgroup?: string
    },
) : CheckboxOption[] => {
    const existedOption = (initialFacets || facets).find(({ filterName }) => filterName === pluralName);

    if (!facets || !existedOption) { return []; }
    return existedOption?.counts?.map((filterFacet) => {
        const currentFacet = facets?.find(({ filterName }) => filterName === pluralName)?.counts?.find(
            ({ filterValue }) => filterValue === filterFacet.filterValue,
        );

        let { filterValue } = filterFacet;
        let count = currentFacet?.count || 0;

        if (Array.isArray(filterFacet)) {
            [filterValue, count] = [filterFacet[0], currentFacet[1] || 0];
        }

        let label = null;

        if (dictionary) {
            label = dictionary?.find(it => String(it.id) === String(filterValue))?.label;
            if (!label) return null;
        }
        if (filterValue === null) {
            return null;
        }
        return {
            count,
            customTooltip: renderTooltip ? renderTooltip(filterValue) : null,
            description: descriptionGroup ? t(`${descriptionGroup}.${filterValue}`) : null,
            label: label || (filterValue === 'null' ? 'None' : (tgroup ? t(`${tgroup}.${filterValue}`) : filterValue)),
            value: filterValue,
        };
    }).filter(Boolean);
};

export const getAssetPicture = ({
    assetType,
    configPictures,
}) => {
    if (configPictures && configPictures[assetType]) {
        return configPictures[assetType];
    }

    return assetType === ASSET_TYPES.CONTAINER
        ? PICTURES.uld
        : assetType === ASSET_TYPES.PALLET
            ? PICTURES.airport_pallet : icons.default_asset;
};

export const getEnvironmentFromUrl = (): Environment => {
    if (documentLocationUrl.includes('dev')) {
        return 'DEV';
    } else if (documentLocationUrl.includes('localhost')) {
        return 'POC';
    } else if (documentLocationUrl.includes('test')) {
        return 'TEST';
    } else if (documentLocationUrl.includes('pre')) {
        return 'PRE';
    } else if (documentLocationUrl.includes('poc')) {
        return 'POC';
    } else if (documentLocationUrl.includes('demo')) {
        return 'DEMO';
    } else {
        return 'PROD';
    }
};

export const checkPrivilege = (hasAccess: (role: string) => boolean, item: RouteItem | StandardItem): boolean => {
    if (item.exceptRoles && item.exceptRoles.some(role => hasAccess(role))) {
        return false;
    }

    if (item.sufficientRoles) {
        return (item.sufficientRoles || []).some(role => hasAccess(role));
    }

    return (item.necessaryRoles || []).every(role => hasAccess(role));
};

export const adjustBoundsForZoomIn = (bounds, factor = 0.05) => {
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    const latDiff = (ne.lat() - sw.lat()) * factor;
    const lngDiff = (ne.lng() - sw.lng()) * factor;

    return new google.maps.LatLngBounds(
        new google.maps.LatLng(sw.lat() + latDiff, sw.lng() + lngDiff),
        new google.maps.LatLng(ne.lat() - latDiff, ne.lng() - lngDiff),
    );
};
