import React, { useContext, useEffect, useState, CSSProperties, useMemo, useCallback } from 'react';
import { LayoutContext } from 'Contexts/LayoutContext';
import useCacheContext from 'hooks/useCacheContext';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import parseQueryParams from 'utils/parsePathQueries';
import { TimeRange } from 'dataTypes/common';
import ShortPackagingInfo from 'shared-components/ShortPackagingInfo';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import Card from 'shared-components/Card';
import useGetGatewaysByGatewayNumbers,
{ extractLatestSensorDataWithUniqueGatewayNumbersHistorical }
    from 'hooks/useGetGatewaysByGatewayNumbers/useGetGatewaysByGatewayNumbers';
import BackToLink from 'shared-components/BackToLink';
import useGetCommonData from 'hooks/useGetCommonData';
import { Asset } from 'dataTypes/SecureBackend/apiResponse';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import useConfig from 'hooks/useConfig';
import PeriodSelectorWithDatePicker from 'shared-components/PeriodSelectorWithDatePicker';
import { getAssetPicture } from 'shared-components/common';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';
import {
    PathParams,
    EntitySelectorItem,
    initialEntitySelectorItem,
    ASSET_TYPES,
} from './dataTypes';
import styles from './LocationHistory.style';
import Options from './components/Options';
import {
    initializeTimeRange,
    initializeRequestBody, fetchAssets, initializeEntityList,
    getTimeDiffMinutes,
    LatLngLiteral,
    PolylinePath,
    LocationHistoryRow,
} from './lib';
import useLoadSensorData from './hooks/useLoadSensorData';
import LocationHistoryTable from './components/LocationHistoryTable';
import LocationHistoryMap from './components/LocationHistoryMap';
import icons from '../shared-components/icons';
import useHasAccess, { userRoles } from '../hooks/useHasAccess';

const LocationHistory = () => {
    const { t } = useCustomTranslation();
    const assetTypeLabels = useGetTranslationGroup('ASSET_TYPE_LABEL');
    const config = useConfig();
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const location = useLocation();
    const { getCacheValue } = useCacheContext();
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);
    const { entityNumber: entityNumberInPath = '' } = useParams() as unknown as PathParams;
    const queryParams = parseQueryParams(location.search);
    const [mapMaximized, setMapMaximized] = useState(true);
    const [isCurrentTime, setIsCurrentTime] = useState(true);

    const [firstEntry, setFirstEntry] = useState(true);
    const [requestBody, setRequestBody] = useState<SensorDataRequestBody>(initializeRequestBody(queryParams));
    const [timeRange, setTimeRange] = useState<TimeRange>(initializeTimeRange(
        true,
        queryParams,
        config?.locationHistory?.timeFilterDays === 0.02,
        config?.locationHistory?.timeFilterDays === 1,
        config?.locationHistory?.timeFilterDays === 7,
        config ? config?.locationHistory?.timeFilterDays === 14 : true,
    ));

    const [localTimezone, setLocalTimezone] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [entitySelectorItem, setEntitySelectorItem] = useState<EntitySelectorItem>(
        !entityNumberInPath ? (getCacheValue('entitySelectorItem') || initialEntitySelectorItem)
            : initialEntitySelectorItem,
    );
    const [sampleForSearch, setSampleForSearch] = useState('');

    const [assetListAll, setAssetListAll] = useState(initializeEntityList(entitySelectorItem, 'assets'));
    const {
        userInfo,
    } = useCurrentUserContext();
    const {
        availableHeight,
        setCustomPageTitle,
        setMenuTabs,
    } = useContext(LayoutContext);
    const { entityType } = entitySelectorItem ?? {};
    const isAssetShared = entitySelectorItem?.additionalData?.ownedByCompanyId
        && entitySelectorItem?.additionalData?.ownedByCompanyId !== Number(userInfo?.attributes?.companyId);
    const {
        assetList,
        assetRequestStatus,
    } = useLoadSensorData(entitySelectorItem, 'assets', entityNumberInPath, isAdmin);

    useEffect(() => {
        if (entityNumberInPath && entitySelectorItem.entityNumber !== entityNumberInPath) {
            if (assetList.length > 0) {
                const asset = assetList.find(item => item.entityNumber === entityNumberInPath) || null;

                if (asset) setEntitySelectorItem(asset);
            }
        }
    }, [entityNumberInPath, assetList, setEntitySelectorItem]);

    useEffect(() => {
        setMenuTabs([
            {
                activePage: true,
                iconSrc: '',
                link: isAdmin ? `/administration/assets/${entitySelectorItem.entityNumber}`
                    : `/asset-management/assets/location/${entitySelectorItem.entityNumber}`,
                title: t('MENU_ITEMS.LOCATION_HISTORY'),
            },
            {
                activePage: false,
                hide: isAssetShared && !isAdmin,
                iconSrc: '',
                link: isAdmin ? `/administration/assets/readout/${entitySelectorItem.entityNumber}`
                    : `/asset-management/assets/readout/${entitySelectorItem.entityNumber}`,
                title: t('COMMON.TEMPERATURE'),
            },
            {
                activePage: false,
                hide: isAssetShared && !isAdmin,
                iconSrc: '',
                link: isAdmin ? `/administration/assets/pairings/${entitySelectorItem.entityNumber}`
                    : `/asset-management/assets/pairings/${entitySelectorItem.entityNumber}`,
                title: t('SENSOR_DATA.LOGGER_PAIRINGS'),
            },
        ]);
        setCustomPageTitle(t('MENU_ITEMS.ASSET_DETAILS'));
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, [entitySelectorItem, isAdmin, t]);

    useEffect(() => {
        if (!firstEntry) {
            navigate({
                pathname: `/asset-management/assets/location/${entitySelectorItem?.entityNumber || ''}`,
            });
        }
    }, [entitySelectorItem]);

    useEffect(() => {
        if (timeRange.to) {
            setIsCurrentTime(true);
        }
    }, [timeRange]);

    useEffect(() => {
        if (entitySelectorItem?.entityNumber) {
            if (firstEntry) {
                setFirstEntry(false);
            }

            setTimeRange(initializeTimeRange(true,
                queryParams,
                config?.locationHistory?.timeFilterDays === 0.02 || true,
                config?.locationHistory?.timeFilterDays === 1,
                config?.locationHistory?.timeFilterDays === 7,
                config ? config?.locationHistory?.timeFilterDays === 14 : true,
            ));
        } else {
            setTimeRange({ from: null, to: null });
        }
    }, [entitySelectorItem, queryParams]);

    const assetsFetched = assetRequestStatus === 'SUCCESS';

    const wrapperHeightNum = Number(availableHeight.replaceAll('px', '')) - 140;

    const loadingWrapperStyle: CSSProperties = useMemo(() => ({
        alignItems: 'flex-start',
        display: 'flex',
        flexWrap: 'wrap',
        height: wrapperHeightNum,
        justifyContent: 'space-between',
    }), [assetsFetched, availableHeight]);

    const {
        Get: getLoggerSensorData,
    } = useSkymindBackendEndpoints(isAdmin ? 'admin/assets' : 'assets').requests;
    const {
        data: sensorData,
    } = useCachedQueryRequest({
        key: `${entitySelectorItem?.entityId}_sensor_data_from=${timeRange.from}&to=${timeRange.to}`,
        options: {
            enabled: !!(entitySelectorItem?.entityId && timeRange.from && timeRange.to),
            refetchInterval: config?.locationHistory?.refetchInterval,
            retry: false,
        },
        request: getLoggerSensorData.bind(null, `${
            entitySelectorItem?.entityId
        }/sensor-data?from=${timeRange.from}&to=${timeRange.to}`),
    });

    const {
        data: rawAssets,
    } = useGetCommonData<Asset[]>(isAdmin ? 'admin/assets/search' : 'assets/search', {
        enabled: !!entitySelectorItem?.entityNumber && firstEntry,
        postProcess: it => it.resultList,
        query: entitySelectorItem?.entityNumber ? {
            q: entitySelectorItem?.entityNumber,
        } : {},
    });

    const {
        data: rawAssetsAll,
    } = useGetCommonData<Asset[]>(isAdmin ? 'admin/assets/search' : 'assets/search', {
        enabled: firstEntry,
        postProcess: it => it.resultList,
        query: {
            page: 0,
            pageSize: 1000,
            q: sampleForSearch,
        },
        refetchInterval: config?.locationHistory?.refetchInterval,
    });

    useEffect(() => {
        if (rawAssetsAll) {
            setAssetListAll(fetchAssets(rawAssetsAll, assetTypeLabels));
        }
    }, [rawAssetsAll, assetTypeLabels]);

    const uniqueLastSensorData = useMemo(() => extractLatestSensorDataWithUniqueGatewayNumbersHistorical(sensorData),
        [sensorData]);

    const uniqueGatewayNumbers = useMemo(() => [...new Set(uniqueLastSensorData.map(it => it.gatewayNumber))],
        [uniqueLastSensorData]);

    const {
        gateways = [],
    } = useGetGatewaysByGatewayNumbers({
        enabled: uniqueLastSensorData.length !== 0,
        isAdmin,
        query: {
            gatewayImeiMac: uniqueGatewayNumbers,
        },
    });

    const uniqueSensorDataWithGateways = useMemo(() => uniqueLastSensorData.map(it => {
        const gateway = gateways?.find(gw => gw?.gatewayImeiMac?.toLowerCase() === it?.gatewayNumber?.toLowerCase());

        return {
            ...it,
            gateway,
        };
    }), [uniqueLastSensorData, gateways]);

    const rows: LocationHistoryRow[] = useMemo(() => {
        if (uniqueSensorDataWithGateways?.length) {
            const preparedRows = uniqueSensorDataWithGateways.map((it, ind, arr) => {
                const areaTimeDiffMinutes = getTimeDiffMinutes({ arr, ind, it, sensorData });
                let locationTimeDiffMinutes = getTimeDiffMinutes({ arr, ind, it, sensorData });

                for (let i = ind; i < arr.length; i++) {
                    if (arr[i + 1]?.gateway?.locationName !== it?.gateway?.locationName
                        || arr[i + 1]?.isHistorical || arr[i + 1]?.isNotShared || i + 1 === arr?.length
                    || arr[i]?.isHistorical || arr[i]?.isNotShared) {
                        break;
                    }
                    locationTimeDiffMinutes += getTimeDiffMinutes({
                        arr,
                        ind: i + 1,
                        it: arr[i + 1],
                        sensorData,
                    });
                }

                const timeData = {
                    gateway: it.isHistorical ? null : it.gateway,
                    localTimezone,
                    time: it.sensorData?.t,
                };

                let locationName: string;

                if (it.isNotShared) {
                    locationName = t('SENSOR_DATA.NOT_SHARED');
                } else if (it.isHistorical) {
                    locationName = t('SENSOR_DATA.NOT_CONNECTED');
                } else {
                    locationName = `${it.gateway?.iataCode || ''}\
${it.gateway?.iataCode ? ' | ' : ''}${it.gateway?.locationName || ''}`;
                }

                return {
                    area: it.isHistorical || it.isNotShared ? 'N/A' : it.gateway?.area,
                    areaTimeDiffMinutes,
                    arrLength: uniqueSensorDataWithGateways?.length,
                    isHistorical: it.isHistorical,
                    isNotShared: it.isNotShared,
                    locationName,
                    locationTimeDiffMinutes,
                    timeData,
                };
            });

            let firstEntryIndex = 0;

            preparedRows.forEach((row, i) => {
                const firstLocationName = preparedRows[firstEntryIndex].locationName;

                if (i > 0 && row.locationName === firstLocationName
                && preparedRows[i - 1].locationName !== t('SENSOR_DATA.NOT_SHARED')
                && preparedRows[i - 1].locationName !== t('SENSOR_DATA.NOT_CONNECTED')) {
                    row.locationName = '';
                    row.locationTimeDiffMinutes = null;
                } else {
                    firstEntryIndex = i;
                }
            });

            const finalRows = preparedRows.reduce((acc, it) => {
                const lastRow = acc[acc.length - 1];

                if ((it.locationName === t('SENSOR_DATA.NOT_SHARED')
                        || it.locationName === t('SENSOR_DATA.NOT_CONNECTED'))
                    && lastRow?.locationName === it.locationName) {
                    lastRow.locationTimeDiffMinutes += it.locationTimeDiffMinutes;
                } else {
                    acc.push(it);
                }
                return acc;
            }, []);

            finalRows.forEach(row => {
                row.arrLength = finalRows.length;
            });
            return finalRows;
        }

        if (rawAssets?.length) {
            const [asset] = rawAssets;

            if (!asset?.lastMeasuredData) return [];

            const locationName = `${asset.lastMeasuredData?.iataCode || ''}\
${asset.lastMeasuredData?.iataCode ? ' | ' : ''}${asset.lastMeasuredData?.locationName || ''}`;

            const areaTimeDiffMinutes = null; // only one entry - no time diff

            const timeData = {
                gateway: {
                    geolocation: asset.lastMeasuredData?.geolocation,
                },
                localTimezone,
                time: asset.lastMeasuredData?.temperatureGeolocationTimestamp,
            };

            return [{
                area: asset.lastMeasuredData?.area,
                areaTimeDiffMinutes,
                arrLength: 1,
                locationName,
                timeData,
            }];
        }

        return [];
    }, [uniqueSensorDataWithGateways, localTimezone, rawAssets, sensorData, t]);

    const lines: PolylinePath[] = useMemo(() => uniqueSensorDataWithGateways.map((it, ind, arr) => {
        if (ind === arr.length - 1) {
            return null;
        }

        const nextEntry = arr[ind + 1];

        return [
            {
                lat: it.gateway?.geolocation?.latitude,
                lng: it.gateway?.geolocation?.longitude,
            },
            {
                lat: nextEntry.gateway?.geolocation?.latitude,
                lng: nextEntry.gateway?.geolocation?.longitude,
            },
        ] as LatLngLiteral[];
    }).filter(Boolean), [uniqueSensorDataWithGateways]);

    const selected: ExtractedLoggerData = useMemo(() => {
        if (selectedIndex === null) return null;

        return {
            lastMeasuredLatitude: rows[selectedIndex]?.timeData?.gateway?.geolocation?.latitude,
            lastMeasuredLongitude: rows[selectedIndex]?.timeData?.gateway?.geolocation?.longitude,
            loggerFamily: 'MR_810T',
        };
    }, [uniqueSensorDataWithGateways, selectedIndex]);

    const assetTypeLabel = !entitySelectorItem?.additionalData?.assetType
        ? ''
        : t(`ASSET_TYPE_LABEL.${entitySelectorItem.additionalData.assetType}`);

    const widthPercent = 100;
    const isRightPanelHidden = false;
    const heightTable = `calc(${wrapperHeightNum}px * ${0.4})`;
    const heightMap = `calc(${wrapperHeightNum}px * ${0.58})`;

    const assetPicture = useMemo(() => getAssetPicture({
        assetType: entitySelectorItem.additionalData.assetType,
        configPictures: config?.locationHistory?.configPictures,
    }), [config, entitySelectorItem.additionalData.assetType]);

    const entityLabel = useMemo(() => {
        if (entitySelectorItem?.entityNumber) {
            return `${assetTypeLabel} ${entitySelectorItem.entityNumber}`;
        }
        return '';
    }, [entitySelectorItem, assetTypeLabel]);

    const customFullScreenControl = useCallback(() => {
        setMapMaximized((prev) => !prev);
    }, [setMapMaximized]);

    useEffect(() => {
        if (rows?.length > 0 && selectedIndex !== 0) {
            setSelectedIndex(0);
        }
    }, [rows?.length]);

    return (
        <div style={{
            padding: '0 12px 12px 12px',
        }}
        >
            <BackToLink
                marginBottom={10}
                marginLeft={0}
                marginRight={12}
                marginTop={10}
                title={t('COMMON.ASSETS_LIST')}
                to={isAdmin ? '/administration/assets'
                    : `/asset-management/assets?view=${getCacheValue('VIEW_TYPE') || 'table'}`}
            />
            <div className={classes.contentContainerFullWidth}>
                <Card fullWidth={isRightPanelHidden || mapMaximized}>
                    { isAssetShared && !isAdmin
                        && (
                            <div style={
                                {
                                    alignItems: 'center',
                                    columnGap: '5px',
                                    display: 'flex',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    right: '12px',
                                    top: '12px',
                                }
                            }
                            >
                                <img alt="ownedBy" src={icons.asset_sharing} />
                                <span>
                                    {t('TRACK_AND_TRACE.OWNED_BY')}
                                    {' '}
                                    {entitySelectorItem?.additionalData?.ownedByCompanyName}
                                </span>
                            </div>
                        )}
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    >
                        <h3 className={classes.title}>
                            {
                                entityLabel ? `${entityLabel} | ${t('MENU_ITEMS.LOCATION_HISTORY')}`
                                    : 'Please select an asset'
                            }
                        </h3>
                        {!isRightPanelHidden && (
                            <PeriodSelectorWithDatePicker
                                defaultPeriod="5m"
                                maxDateRange={null}
                                setTimeRange={setTimeRange}
                                timeRange={timeRange}
                            />
                        )}
                    </div>
                    <div style={loadingWrapperStyle}>
                        <LocationHistoryTable
                            height={heightTable}
                            rows={rows}
                            selectedIndex={selectedIndex}
                            widthPercent={widthPercent}
                            onRowClick={(_, i) => setSelectedIndex(i)}
                        />
                        <LocationHistoryMap
                            height={heightMap}
                            mapFullscreen
                            polylines={lines.every(Boolean) ? lines : []}
                            selected={selected}
                            widthPercent={widthPercent}
                        />
                    </div>
                </Card>
                {isRightPanelHidden && (
                    <div className={classes.contentRightColumn}>
                        <Options
                            assetList={assetListAll}
                            disabled={entityType === null}
                            entitySelectorItem={entitySelectorItem}
                            isCurrentTime={isCurrentTime}
                            localTimezone={localTimezone}
                            mapData={{
                                customFullScreenControl,
                                height: '221.5px',
                                mapMaximized,
                                polylines: lines.every(Boolean) ? lines : [],
                                selected,
                                widthPercent,
                            }}
                            requestBody={requestBody}
                            sampleForSearch={sampleForSearch}
                            setEntitySelectorItem={setEntitySelectorItem}
                            setIsCurrentTime={setIsCurrentTime}
                            setLocalTimezone={setLocalTimezone}
                            setRequestBody={setRequestBody}
                            setSampleForSearch={setSampleForSearch}
                            setShowMap={() => {}}
                            setTimeRange={setTimeRange}
                            showMapInternally={!mapMaximized}
                            timeRange={timeRange}
                        />
                        {
                            (entityType === 'assets') && (
                                <ShortPackagingInfo
                                    model={
                                        entitySelectorItem.additionalData.assetType === ASSET_TYPES.CONTAINER
                                            ? t('ASSET_TYPE_LABEL.CONTAINER')
                                            : entitySelectorItem.additionalData.assetType === ASSET_TYPES.PALLET
                                                ? t('ASSET_TYPE_LABEL.PALLET')
                                                : t('ASSET_TYPE_LABEL.OTHER')
                                    }
                                    picture={assetPicture}
                                />
                            )
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default LocationHistory;
