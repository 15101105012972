import { useState, useEffect, useMemo } from 'react';
import axios, { AxiosResponse } from 'axios';

import { Environment } from 'dataTypes/common';
import { assetsPath } from 'shared-components/constants';
import { ASSET_TYPES_ENUM } from 'SensorDataCommon/dataTypes';
import { getEnvironmentFromUrl } from 'shared-components/common';

const url = `${assetsPath}/assets/config/skymind.json`;

type ConfigKey = Environment | 'default';

export type configPictures = {
    [key in ASSET_TYPES_ENUM]?: string;
};

type ConfigEnv = {
  locationHistory?: {
    configPictures?: configPictures,
    isRightPanelHidden?: boolean,
    mapId?: string,
    refetchInterval?: number,
    timeFilterDays?: 0.02 | 1 | 7 | 14
  }
};

type ConfigFull = {
    [key in ConfigKey]: ConfigEnv;
};

// uses in case of error fetching config from the server
const defaultConfig: Partial<ConfigFull> = {
    default: {
        locationHistory: {
            isRightPanelHidden: false,
            refetchInterval: 600000,
            timeFilterDays: 0.02,
        },
    },
    POC: {
        locationHistory: {
            configPictures: {
                CONTAINER: 'https://assets.dev.skymind.com/assets/containerTypes/prime_container.png',
            },
            isRightPanelHidden: true,
            mapId: '3e3ab7e036f78796',
            refetchInterval: 3000,
            timeFilterDays: 0.02,
        },
    },
};

let configCache: ConfigEnv | null = null;

const useConfig = () => {
    const [config, setConfig] = useState<ConfigEnv>(configCache || {});

    const getEnvironmentConfig = useMemo(() => (fullConfig: Partial<ConfigFull>): ConfigEnv => {
        const environment = getEnvironmentFromUrl();

        return fullConfig[environment] || fullConfig.default;
    }, []);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response: AxiosResponse<Partial<ConfigFull>> = await axios.get(url);
                const newConfig = getEnvironmentConfig(response.data);

                configCache = newConfig;
                setConfig(newConfig);
            } catch (error) {
                global.console.error('Failed to fetch config:', error);
                const newConfig = getEnvironmentConfig(defaultConfig);

                configCache = newConfig;
                setConfig(newConfig);
            }
        };

        if (!configCache) {
            fetchConfig()
                .then(r => r);
        }
    }, []);

    if (configCache) {
        return configCache;
    }

    return config;
};

export default useConfig;
